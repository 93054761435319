import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {OrderDraft} from "../_models/order-draft";
import {PAY_METHODS} from "../_maps/pay-methods";
import {PAY_METHOD_OPTIONS_MAP} from "../_maps/pay-method-options";
import {UserInfoService} from "../_services/user-info.service";
import {Insurance} from "../_models/insurance";
import {InsuranceEditorComponent} from "../insurance-editor/insurance-editor.component";
import {PaymentDistributionSchemaService} from "../_services/payment-distribution-schema.service";
import {PaymentDistributionSchema} from "../_models/payment-distribution-schema";
import {SelectedPaymentDistribution} from "../_models/selected-payment-distribution";
import {PayMethodsService} from "../_services/pay-methods.service";
import {AvailablePayMethod} from "../_models/available-pay-method";

@Component({
  selector: 'draft-pay-editor',
  templateUrl: './draft-pay-editor.component.html',
  styleUrls: ['./draft-pay-editor.component.css']
})
export class DraftPayEditorComponent implements OnInit {
  @Input() draft: OrderDraft;
  @Output() onUpdated = new EventEmitter<OrderDraft>();

  payMethod: string;
  payMethods: any = PAY_METHODS;
  payMethodOption: string;
  availableOptions: string[] = [];
  optionsEnabled = false;
  isCostEditable = false;
  isPaymentDistributionEditable = false;
  cost = 0;
  paymentDistributionSchemas: PaymentDistributionSchema[] = [];
  paymentDistributionSchemaId: number = 0;

  private availablePayMethods: AvailablePayMethod[] = [];

  constructor(private userInfoService: UserInfoService,
              private payMethodsService: PayMethodsService,
              private paymentDistributionSchemaService: PaymentDistributionSchemaService) {
  }

  ngOnInit() {
    this.payMethodOption = this.draft.pay_method_option;
    this.cost = this.draft.cost;
    this.isCostEditable = this.isPaymentDistributionEditable = this.userInfoService.isFreighter();

    this.initPayMethods();

    if(this.isPaymentDistributionEditable)
      this.initPaymentDistributionSchemas();
  }

  private initPayMethods(): void {
    if(this.draft.delivery)
      this.initPayMethodsForDelivery();
    else
      this.initPayMethodsForTaxi();
  }

  private initPayMethodsForTaxi(): void {
    if(this.userInfoService.isCustomer()) {
      this.payMethodsService.getMyAvailablePayMethods().subscribe(
        methods => this.applyAvailablePayMethods(methods),
        () => {}
      );
    } else if(this.userInfoService.isPrivilegedUser()) {
      if(this.draft.client && this.draft.client.id) {
        this.payMethodsService.getAvailablePayMethodsForUser(this.draft.client.id).subscribe(
          methods => this.applyAvailablePayMethods(methods),
          () => {}
        );
      } else {
        this.payMethodsService.getAvailablePayMethodsForUser(0).subscribe(
          methods => this.applyAvailablePayMethods(methods),
          () => {}
        );
      }
    }
  }

  initPayMethodsForDelivery(): void {
    if(!this.draft.delivery_schema)
      return;

    if(this.userInfoService.isPrivilegedUser()) {
      this.payMethodsService
        .getAvailablePayMethodsForUserAndDeliverySchema(this.draft.client.id, this.draft.delivery_schema.id)
        .subscribe(
          methods => this.applyAvailablePayMethods(methods),
          () => {}
        );
    } else {
      this.payMethodsService
        .getMyAvailablePayMethodsForDeliverySchema(this.draft.delivery_schema.id)
        .subscribe(
          methods => this.applyAvailablePayMethods(methods),
          () => {}
        );
    }
  }

  private applyAvailablePayMethods(availablePayMethods: AvailablePayMethod[]): void {
    this.availablePayMethods = availablePayMethods;
    this.payMethods = {};
    let firstPayMethod: string = null;
    for(let method of availablePayMethods) {
      this.payMethods[method.method] = PAY_METHODS[method.method];
      if(firstPayMethod == null)
        firstPayMethod = method.method;
    }

    // Хак, чтобы предотвратить сброс выбранного метода.
    this.payMethod = firstPayMethod;
    setTimeout(() => {
      this.payMethod = this.draft.pay_method || firstPayMethod;
      this.initOptions();
    }, 0);
    this.initOptions();
  }

  private initOptions(): void {
    this.availableOptions = [];
    for(let method of this.availablePayMethods) {
      if(method.method == this.payMethod) {
        if(method.option)
          this.availableOptions.push(method.option);
        else if(PAY_METHOD_OPTIONS_MAP[method.method])
          this.availableOptions.push(PAY_METHOD_OPTIONS_MAP[method.method][0]);
      }
    }
    this.optionsEnabled = this.availableOptions.length > 0;
    this.payMethodOption = this.optionsEnabled ? this.draft.pay_method_option || this.availableOptions[0] : null;
  }

  private initPaymentDistributionSchemas(): void {
    this.paymentDistributionSchemaService
      .getFreighterPrivateSchemas(this.userInfoService.getFreighter())
      .subscribe(
        schemas => this.paymentDistributionSchemas = schemas,
        () => {}
      )
    ;
    this.paymentDistributionSchemaId = this.draft.payment_distribution_schemas
      && this.draft.payment_distribution_schemas.length > 0
      && this.draft.payment_distribution_schemas[0].schema.id
      || 0;
  }

  private applyPayMethod(): void {
    this.draft.pay_method = this.payMethod;
    this.draft.pay_method_option = this.payMethodOption;
  }

  private applyPaymentDistributionSchema(): void {
    if(!this.isPaymentDistributionEditable)
      return;

    if(!this.draft.payment_distribution_schemas || this.paymentDistributionSchemaId == 0)
      this.draft.payment_distribution_schemas = [];

    if(this.paymentDistributionSchemaId != 0) {
      let selectedSchemas = this.paymentDistributionSchemas.filter(s => s.id == this.paymentDistributionSchemaId);
      if(selectedSchemas.length == 0)
        return;

      let draftSchema = new SelectedPaymentDistribution();
      draftSchema.schema = selectedSchemas[0];

      this.draft.payment_distribution_schemas = [draftSchema];
    }
  }

  onSave() {
    this.applyPayMethod();
    this.applyPaymentDistributionSchema();

    this.draft.cost = this.cost;

    console.log(this.draft);

    this.onUpdated.emit(this.draft);
  }

  onChangePayMethod(): void {
    this.initOptions();
  }
}
