import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Order} from "../_models/order";
import {LoaderService} from "../_services/loader.service";
import {OrderService} from "../_services/order.service";
import {PAY_METHODS} from "../_maps/pay-methods";
import {PAY_METHOD_OPTIONS_MAP} from "../_maps/pay-method-options";
import {UserInfoService} from "../_services/user-info.service";
import {PaymentDistributionSchema} from "../_models/payment-distribution-schema";
import {PaymentDistributionSchemaService} from "../_services/payment-distribution-schema.service";
import {AvailablePayMethod} from "../_models/available-pay-method";
import {PayMethodsService} from "../_services/pay-methods.service";
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'payment-editor',
  templateUrl: './payment-editor.component.html',
  styleUrls: ['./payment-editor.component.css']
})
export class PaymentEditorComponent implements OnInit {
  @Input() order: Order;
  @Output() onUpdated = new EventEmitter<Order>();

  fixedDiscount: number;
  discount: number;
  payMethod: string;
  payMethods: any = PAY_METHODS;
  payMethodOption: string;
  availableOptions: string[] = [];
  optionsEnabled = false;
  isCostEditable = false;
  isPaymentDistributionEditable = false;
  cost = 0;
  paymentDistributionSchemas: PaymentDistributionSchema[] = [];
  paymentDistributionSchemaId: number = 0;

  private availablePayMethods: AvailablePayMethod[] = [];

  constructor(
    private loaderService: LoaderService,
    private orderService: OrderService,
    private paymentDistributionSchemaService: PaymentDistributionSchemaService,
    private payMethodsService: PayMethodsService,
    private userInfoService: UserInfoService,
  ) { }

  ngOnInit() {
    this.fixedDiscount = this.order.fixed_discount;
    this.discount = this.order.discount;
    this.payMethod = this.order.pay_method;
    this.payMethodOption = this.order.pay_method_option;
    this.cost = this.order.cost;
    this.isCostEditable = this.isPaymentDistributionEditable = this.userInfoService.isFreighter();

    this.initPayMethods();

    this.initPaymentDistributionSchemas();
  }

  private initPayMethods(): void {
    if(this.order.draft.delivery)
      this.initPayMethodsForDelivery();
    else
      this.initPayMethodsForTaxi();
  }

  private initPayMethodsForTaxi(): void {
    if(this.userInfoService.isCustomer()) {
      this.payMethodsService.getMyAvailablePayMethods().subscribe(
        methods => this.applyAvailablePayMethods(methods),
        () => {}
      );
    } else if(this.userInfoService.isPrivilegedUser()) {
      this.payMethodsService.getAvailablePayMethodsForUser(this.order.draft.client.id).subscribe(
        methods => this.applyAvailablePayMethods(methods),
        () => {}
      );
    }
  }

  initPayMethodsForDelivery(): void {
    if(!this.order.draft.delivery_schema)
      return;

    if(this.userInfoService.isPrivilegedUser()) {
      this.payMethodsService
        .getAvailablePayMethodsForUserAndDeliverySchema(this.order.draft.client.id, this.order.draft.delivery_schema.id)
        .subscribe(
          methods => this.applyAvailablePayMethods(methods),
          () => {}
        );
    } else {
      this.payMethodsService
        .getMyAvailablePayMethodsForDeliverySchema(this.order.draft.delivery_schema.id)
        .subscribe(
          methods => this.applyAvailablePayMethods(methods),
          () => {}
        );
    }
  }

  private applyAvailablePayMethods(availablePayMethods: AvailablePayMethod[]): void {
    this.availablePayMethods = availablePayMethods;
    this.payMethods = {};
    let firstPayMethod: string = null;
    for(let method of availablePayMethods) {
      this.payMethods[method.method] = PAY_METHODS[method.method];
      if(firstPayMethod == null)
        firstPayMethod = method.method;
    }

    // Хак, чтобы предотвратить сброс выбранного метода.
    this.payMethod = firstPayMethod;
    setTimeout(() => {
      this.payMethod = this.order.pay_method || firstPayMethod;
      this.initOptions();
    }, 0);
    this.initOptions();
  }

  private initOptions(): void {
    this.availableOptions = [];
    for(let method of this.availablePayMethods) {
      if(method.method == this.payMethod) {
        if(method.option)
          this.availableOptions.push(method.option);
        else if(PAY_METHOD_OPTIONS_MAP[method.method])
          this.availableOptions.push(PAY_METHOD_OPTIONS_MAP[method.method][0]);
      }
    }
    this.optionsEnabled = this.availableOptions.length > 0;
    this.payMethodOption = this.optionsEnabled ? this.order.pay_method_option || this.availableOptions[0] : null;
  }

  private initPaymentDistributionSchemas(): void {
    if(!this.userInfoService.isFreighter()) {
      this.paymentDistributionSchemas = [];
      return;
    }

    this.paymentDistributionSchemaService
      .getFreighterPrivateSchemas(this.userInfoService.getFreighter())
      .subscribe(
        schemas => this.paymentDistributionSchemas = schemas,
        () => {}
      )
    ;
    this.paymentDistributionSchemaId = this.order.payment_distribution_schemas
      && this.order.payment_distribution_schemas.length > 0
      && this.order.payment_distribution_schemas[0].schema.id
      || 0;
  }

  onSave() {
    this.loaderService.show();
    firstValueFrom(this.orderService.updateOrderDiscount(this.order, this.discount, this.fixedDiscount))
      .then(() => {
        return (this.payMethod != this.order.pay_method || this.payMethodOption != this.order.pay_method_option)
          && firstValueFrom(this.orderService.updateOrderPayMethod(this.order, this.payMethod, this.payMethodOption));
      })
      .then(() => this.isCostEditable && this.cost != this.order.cost && firstValueFrom(this.orderService.updateOrderCost(this.order, this.cost)))
      .then(() => {
        if(!this.isPaymentDistributionEditable || this.paymentDistributionSchemaId == this.order.payment_distribution_schemas[0].schema.id)
          return;

        let filteredSchemas = this.paymentDistributionSchemas.filter(s => s.id == this.paymentDistributionSchemaId);
        if(filteredSchemas.length == 0)
          return;

        return firstValueFrom(this.orderService.updateOrderPaymentDistributionSchema(this.order, filteredSchemas[0]));
      })
      .then(() => {
        this.loaderService.hide();
        this.order.fixed_discount = this.fixedDiscount;
        this.order.discount = this.discount;
        this.order.pay_method = this.payMethod;
        this.onUpdated.emit(this.order);
      })
      .catch(() => this.loaderService.hide());
  }

  onChangePayMethod(): void {
    this.initOptions();
  }
}
