import {Destination} from "./destination";
import {OrderPeriodHistoryRow} from "./order-period-history-row";
import {Transport} from "./transport";
import {Employer} from "./employer";
import {Point} from "./point";
import {TransportTariff} from "./transport-tariff";
import {LoaderTariff} from "./loader-tariff";
import {AssemblerTariff} from "./assembler-tariff";
import {LiftingTariff} from "./lifting-tariff";
import {OrderPeriodExternalHistoryRow} from "./order-period-external-history-row";
import {OrderArea} from "./order-area";
import {OrderDriverAuthLink} from "./order-driver-auth-link";

export class OrderPeriod {
  id: number;
  start: string;
  end: string;
  loaders: number;
  assembly: boolean;
  distance: number;
  out_mkad_distance: number;
  time_at_way: number;
  comment: string;
  status: string;
  has_package: string;
  execution_status: string;
  destinations: Destination[];
  history: OrderPeriodHistoryRow[];
  external_history: OrderPeriodExternalHistoryRow[];
  transport: Transport;
  employer: Employer;
  points: Point[];
  tariff: TransportTariff;
  loader_tariff: LoaderTariff;
  assembler_tariff: AssemblerTariff;
  lifting_tariff: LiftingTariff;
  use_calculated_path: boolean;
  external_order_status: string;
  areas: OrderArea[];
  order_driver_auth_links: OrderDriverAuthLink[];
}
