
import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {Observable} from "rxjs";
import {Order} from "../_models/order";
import {OrderList} from "../_models/order-list";
import {OrderListFilter} from "../_models/order-list-filter";
import {OrderPeriod} from "../_models/order-period";
import {Destination} from "../_models/destination";
import {Crew} from "../_models/crew";
import {Image} from "../_models/image";
import {TransportTariff} from "../_models/transport-tariff";
import {LoaderTariff} from "../_models/loader-tariff";
import {AssemblerTariff} from "../_models/assembler-tariff";
import {LiftingTariff} from "../_models/lifting-tariff";
import {Employer} from "../_models/employer";
import {ApiUtilsService} from "./api-utils.service";
import {DateUtils} from "../_utils/date-utils";
import {OrderReport} from "../_models/order-report";
import {SummaryReport} from "../_models/report/summary-report";
import {Account} from "../_models/account";
import {AccountSumHistoryRow} from "../_models/account-sum-history-row";
import {OrderPeriodStatus} from "../_models/search-state/order-period-status";
import {CityService} from "./city.service";
import {RouteSheetSend} from "../_models/route-sheet-send";
import {SummaryReportV2} from "../_models/report/summary-report.v2";
import {OrderDraft} from "../_models/order-draft";
import {PaymentDistributionSchema} from "../_models/payment-distribution-schema";
import {FreighterClient} from "../_models/freighter-client";
import {FreighterClientService} from "./freighter-client.service";
import {AdjustmentCost} from "../_models/adjustment-cost";
import {HttpResponse} from "@angular/common/http";

const PAGE_SIZE = 10;

@Injectable()
export class OrderService {
  constructor(
    private _requestService: RequestWithErrorHandlerService,
    private apiUtilsService: ApiUtilsService,
    private cityService: CityService
  ) { }

  getOrders(page?: number, filter?: OrderListFilter): Observable<OrderList> {
    let filterClone = filter && filter.clone() || new OrderListFilter();

    return this._requestService
      .get(`${this.apiUtilsService.getPrefixForList()}/orders.json`, {
        status: filter && filter.status || null,
        search: filter && filter.search || null,
        'orderEnd[start]': filter && filter.after ? DateUtils.formatDate(filter.after) + " 00:00:00" : null,
        'orderEnd[end]': filter && filter.before ? DateUtils.formatDate(filter.before) + " 23:59:59": null,
        companyClient: filter && filter.companyClient || null,
        debtMore: filter && filter.debt,
        payMethod: filter && filter.payMethod,
        freighters: filter && filter.freighters.map(f => f.id).join(','),
        employers: filter && filter.employers.map(f => f.id).join(','),
        trackNumber: filter && filter.trackNumber || null,
        cargoCode: filter && filter.cargoCode || null,
        city: this.cityService.activeCity && this.cityService.activeCity.id,
        offset: (page || 0) * PAGE_SIZE,
        size: PAGE_SIZE,
        view: 'for_orders'
      }).pipe(
      map(r => new OrderList(
        page || 0,
        PAGE_SIZE,
        parseInt(r.headers.get('X-Total-Count')),
        filterClone,
        r.body.orders as Order[])
      ));
  }

  getOrder(freighterId: number, id: number): Observable<Order> {
    return this._requestService
      .get(`${this.apiUtilsService.getPrefixByFreighterId(freighterId)}/orders/${id}.json`, {
        view: 'for_orders',
        withCalculation: true
      }).pipe(
      map(r => r.body.order as Order))
      ;
  }

  getOrderParticipantSumHistory(order: Order, participant: Account): Observable<AccountSumHistoryRow[]> {
    return this._requestService
      .get(`/support/me/orders/${order.id}/account_sum_history/${participant.id}.json`).pipe(
      map(r => r.body as AccountSumHistoryRow[]))
    ;
  }

  getOrderFreighterSumHistory(order: Order): Observable<AccountSumHistoryRow[]> {
    return this._requestService
          .get(`/support/me/orders/${order.id}/account_sum_history/freighter.json`).pipe(
          map(r => r.body as AccountSumHistoryRow[]))
          ;
  }

  getOrderClientSumHistory(order: Order): Observable<AccountSumHistoryRow[]> {
    return this._requestService
      .get(`/customer/me/orders/${order.id}/client/account_sum_history.json`).pipe(
      map(r => r.body as AccountSumHistoryRow[]))
    ;
  }

  getOrderCostAdjustments(order: Order): Observable<AdjustmentCost[]> {
    return this._requestService
      .get(`${this.apiUtilsService.getPrefixByFreighterId(order.freighter.id, true)}/orders/${order.id}/cost_adjustments.json`).pipe(
      map(r => r.body as AdjustmentCost[]))
    ;
  }

  getOrderForSupport(id: number, requestFor: string = 'detail'): Observable<Order> {
    return this._requestService
      .get(`/support/me/orders/${id}.json`, { 'for': requestFor }).pipe(
      map(r => r.body.order as Order))
    ;
  }

  getOrderReport(id: number): Observable<OrderReport> {
    return this._requestService
      .get(`/support/me/orders/${id}/report.json`).pipe(
      map(r => r.body.report as OrderReport))
      ;
  }

  getOrderReportForFreighter(freighterId: number, id: number): Observable<OrderReport> {
    return this._requestService
      .get(`${this.apiUtilsService.getPrefixByFreighterId(freighterId)}/orders/${id}/report.json`).pipe(
      map(r => r.body.report as OrderReport))
      ;
  }

  getOrderReports(ordersIds: number[]): Observable<OrderReport[]> {
    return this._requestService
      .get(`/support/me/orders/reports.json`, { ids: ordersIds }).pipe(
      map(r => r.body as OrderReport[]))
      ;
  }

  updatePeriod(order: Order, period: OrderPeriod): Observable<HttpResponse<any>> {
    return this._requestService
      .put(`${this.apiUtilsService.getPrefixForOrder(order)}/orders/${order.id}/period/${period.id}.json`, {
        start: OrderService.convertDate(new Date(period.start)),
        end: OrderService.convertDate(new Date(period.end)),
        loaders: period.loaders,
        comment: period.comment
      });
  }

  updatePaymentsStatus(status: string, filter: OrderListFilter, ordersId?: number[]): Observable<HttpResponse<any>> {
    return this.updateOrderInvoicesStatus('/orders/payment/status.json', status, filter, ordersId);
  }

  updatePayoutsStatus(status: string, filter: OrderListFilter, ordersId?: number[]): Observable<HttpResponse<any>> {
    return this.updateOrderInvoicesStatus('/orders/payout/status.json', status, filter, ordersId);
  }

  sendRouteSheets(filter: OrderListFilter, ordersId?: number[]): Observable<HttpResponse<any>> {
    return this._requestService.put(
      `${this.apiUtilsService.getPrefixForList()}/orders/route-sheets/sends.json`,
      this.filterToData(filter, ordersId)
    );
  }

  getRouteSheetSends(filter: OrderListFilter, ordersId?: number[]): Observable<RouteSheetSend[]> {
    return this._requestService
      .get(
        `${this.apiUtilsService.getPrefixForList()}/orders/route-sheets/sends.json`,
        this.filterToData(filter, ordersId)
      ).pipe(
      map(r => r.body as RouteSheetSend[]))
      ;
  }

  getOrdersStatuses(ordersIds: number[]): Observable<OrderPeriodStatus[]> {
    let params = {};
    for(let i in ordersIds) {
      let orderId = ordersIds[i];
      let key = `ordersIds[${i}]`;
      params[key] = orderId;
    }
    return this._requestService
      .get(`${this.apiUtilsService.getPrefixForList()}/orders/statuses.json`, params).pipe(
      map(r => r.body as OrderPeriodStatus[]));
  }

  private updateOrderInvoicesStatus(uri: string, status: string, filter: OrderListFilter, ordersId?: number[]): Observable<HttpResponse<any>> {
    let data = this.filterToData(filter, ordersId);
    data.newStatus = status;

    return this._requestService.put(`${this.apiUtilsService.getPrefixForList()}${uri}`, data);
  }

  getSummaryReport(filter: OrderListFilter, ordersId?: number[]): Observable<SummaryReport> {
    return this._requestService
      .get(`${this.apiUtilsService.getPrefixForList()}/orders/summary.json`, this.filterToData(filter, ordersId)).pipe(
      map(r => r.body as SummaryReport))
    ;
  }

  getSummaryReportV2(filter: OrderListFilter, ordersId?: number[]): Observable<SummaryReportV2> {
    return this._requestService
      .get(`${this.apiUtilsService.getPrefixForList()}/orders/summary/v2.json`, this.filterToData(filter, ordersId)).pipe(
      map(r => r.body as SummaryReportV2))
    ;
  }

  private filterToData(filter: OrderListFilter, ordersId?: number[]): any {
    let data = {};

    if(ordersId && ordersId.length != 0) {
      data = {
        ordersId: ordersId.join(','),
      }
    } else {
      data = {
        status: filter && filter.status || null,
        search: filter && filter.search || null,
        'orderEnd[start]': filter && filter.after ? DateUtils.formatDate(filter.after) + " 00:00:00" : null,
        'orderEnd[end]': filter && filter.before ? DateUtils.formatDate(filter.before) + " 23:59:59": null,
        companyClient: filter && filter.companyClient || null,
        debtMore: filter && filter.debt,
        payMethod: filter && filter.payMethod,
        city: this.cityService.activeCity && this.cityService.activeCity.id,
        freighters: filter && filter.freighters.map(f => f.id).join(','),
        employers: filter && filter.employers.map(f => f.id).join(','),
      }
    }

    return data;
  }

  getMonthlyOrders(monthStart: Date, monthEnd: Date): Observable<Order[]> {
      let mStart = this.formatDate(monthStart, 0, 0, 0);
      let mEnd = this.formatDate(monthEnd, 23, 59, 59);
      return this._requestService
        .get(`${this.apiUtilsService.getPrefixForList()}/orders.json`, {
          'period[start]': mStart,
          'period[end]': mEnd,
          'status': 'new,checkingnegotiation,agree,not_agree,accepted,declined,no_answer,completed,canceled_by_freighter,canceled_by_client',
          'view': 'for_orders'
        }).pipe(
        map(r => r.body.orders as Order[]));
  }

    formatDate(date: Date, hours: number, minutes: number, seconds: number) {
        let year = date.getFullYear();
        let month = this.checkTwoDigits(date.getMonth() + 1);
        let day = this.checkTwoDigits(date.getDate());
        hours = this.checkTwoDigits(hours);
        minutes = this.checkTwoDigits(minutes);
        seconds = this.checkTwoDigits(seconds);

        return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
    }

    checkTwoDigits(num) {
        if (num < 10) {
            return '0' + num.toString();
        }
        else {
            return num.toString();
        }
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }

  updateOrderDiscount(order: Order, discount: number, fixedDiscount: number): Observable<HttpResponse<any>> {
    return this._requestService
      .put(`${this.apiUtilsService.getPrefixForOrder(order)}/orders/${order.id}/discount.json`, {
        discount: discount,
        fixedDiscount: fixedDiscount
      });
  }

  updateOrderStatus(order: Order, status: string): Observable<HttpResponse<any>> {
    return this._requestService
      .put(`${this.apiUtilsService.getPrefixForOrder(order)}/orders/${order.id}/status.json`, {
        status: status
      });
  }

  removePeriodDestination(order: Order, period: OrderPeriod, destination: Destination): Observable<HttpResponse<any>> {
    return this._requestService.delete(`${this.apiUtilsService.getPrefixForOrder(order)}/orders/${order.id}/calendar/${period.id}/destinations/${destination.id}.json`);
  }

  updatePeriodDestination(order: Order, period: OrderPeriod, destination: Destination, sort: number): Observable<HttpResponse<any>> {
    return this._requestService.put(`${this.apiUtilsService.getPrefixForOrder(order)}/orders/${order.id}/period/${period.id}/destinations/${destination.id}.json`, {
      lat: destination.destination.lat,
      lon: destination.destination.lon,
      addr: destination.destination.addr,
      type: destination.type,
      clientLegalEntity: destination.client_legal_entity && destination.client_legal_entity.id || null,
      contactName: destination.contact_name,
      contactPhone: destination.contact_phone,
      contactSendSms: destination.contact_send_sms,
      elevator: destination.elevator,
      floor: destination.floor,
      lifting: destination.lifting,
      loading: destination.loading,
      unloading: destination.unloading,
      unloadingInternetShopOrder: destination.unloading_internet_shop_order,
      deliveryTo: destination.delivery_to,
      comment: destination.comment,
      productLines: destination.delivery_product && destination.delivery_product.lines,
      shipment: destination.delivery_product && {
        length: destination.delivery_product.length,
        width: destination.delivery_product.width,
        height: destination.delivery_product.height,
        weight: destination.delivery_product.weight
      },
      sort: sort
    });
  }

  updatePeriodDestinationPayMethod(order: Order, period: OrderPeriod, destination: Destination, payMethod: string, externalPayLink: boolean): Observable<HttpResponse<any>> {
    return this._requestService.put(
      `${this.apiUtilsService.getPrefixForOrder(order)}/orders/${order.id}/calendar/${period.id}/destinations/${destination.id}/pay_method.json`,
      {
        method: payMethod,
        externalPayLink
      }
    )
  }

  addPeriodDestination(order: Order, period: OrderPeriod, destination: Destination, sort: number): Observable<HttpResponse<any>> {
    return this._requestService.post(`${this.apiUtilsService.getPrefixForOrder(order)}/orders/${order.id}/calendar/${period.id}/destinations.json`, {
      lat: destination.destination.lat,
      lon: destination.destination.lon,
      addr: destination.destination.addr,
      type: destination.type,
      clientLegalEntity: destination.client_legal_entity && destination.client_legal_entity.id || null,
      contactName: destination.contact_name,
      contactPhone: destination.contact_phone,
      contactSendSms: destination.contact_send_sms,
      elevator: destination.elevator,
      floor: destination.floor,
      lifting: destination.lifting,
      loading: destination.loading,
      unloading: destination.unloading,
      unloadingInternetShopOrder: destination.unloading_internet_shop_order,
      deliveryTo: destination.delivery_to,
      comment: destination.comment,
      productLines: destination.delivery_product && destination.delivery_product.lines,
      shipment: destination.delivery_product && {
        length: destination.delivery_product.length,
        width: destination.delivery_product.width,
        height: destination.delivery_product.height,
        weight: destination.delivery_product.weight
      },
      sort: sort
    });
  }

  updatePeriodCrew(order: Order, period: OrderPeriod, crew: Crew): Observable<HttpResponse<any>> {
    return this._requestService.put(`${this.apiUtilsService.getPrefixForOrder(order)}/orders/${order.id}/period/${period.id}/crew.json`, {
      id: crew.id
    });
  }

  updateOrderComment(order: Order, comment: string): Observable<HttpResponse<any>>  {
    return this._requestService.put(`${this.apiUtilsService.getPrefixForOrder(order)}/orders/${order.id}/comment.json`, {
      comment: comment
    });
  }

  updateOrderNotes(order: Order, notes: string): Observable<HttpResponse<any>>  {
    return this._requestService.put(`${this.apiUtilsService.getPrefixForOrder(order)}/orders/${order.id}/notes.json`, {
      notes
    });
  }

  generateDriverAuthLink(order: Order, period: OrderPeriod): Observable<HttpResponse<any>> {
    return this._requestService.post(`${this.apiUtilsService.getPrefixForOrder(order)}/orders/${order.id}/period/${period.id}/driver_auth_link.json`, {});
  }

  updateDriverIntercomDialog(order: Order, link: string): Observable<HttpResponse<any>>  {
    return this._requestService.put(`${this.apiUtilsService.getPrefixForOrder(order)}/orders/${order.id}/driver_intercom_dialog.json`, {
      link
    });
  }

  updateOrderLoaders(order: Order, loaders: number): Observable<HttpResponse<any>>  {
    return this._requestService.put(`${this.apiUtilsService.getPrefixForOrder(order)}/orders/${order.id}/loaders.json`, {
      count: loaders
    });
  }

  updateOrderAssembly(order: Order, required: boolean): Observable<HttpResponse<any>>  {
    return this._requestService.put(`${this.apiUtilsService.getPrefixForOrder(order)}/orders/${order.id}/assembly.json`, {
      required: required ? 1 : 0
    });
  }

  updateOrderPayMethod(order: Order, method: string, methodOption?: string): Observable<HttpResponse<any>>  {
    return this._requestService.put(`${this.apiUtilsService.getPrefixForOrder(order)}/orders/${order.id}/pay_method.json`, {
      method: method,
      methodOption: methodOption
    });
  }

  updateOrderCost(order: Order, cost: number): Observable<HttpResponse<any>>  {
    return this._requestService.put(`${this.apiUtilsService.getPrefixForOrder(order)}/orders/${order.id}/cost.json`, {
      cost
    });
  }

  updateOrderPaymentDistributionSchema(order: Order, schema: PaymentDistributionSchema): Observable<HttpResponse<any>>  {
    return this._requestService.put(`${this.apiUtilsService.getPrefixForOrder(order)}/orders/${order.id}/payment_distribution_schema.json`, {
      schema: schema.id
    });
  }

  updateOrderFreighterClient(order: Order, client: FreighterClient): Observable<number>  {
    return this._requestService
      .put(`${this.apiUtilsService.getPrefixForOrder(order)}/orders/${order.id}/freighter_client.json`, {
        freighterClient: FreighterClientService.clientToData(client)
      }).pipe(
      map(r => r.body.clientId as number))
      ;
  }

  /**
   * Добавление страховок
   *
   * @param order
   */
  addInsurances(order: Order): Observable<HttpResponse<any>> {
    let data = order.insurances.map(i => {
      return {
        section: i.section,
        cargoDescription: i.cargo_description,
        cargoCost: i.cargo_cost,
      }
    });
    return this._requestService.post(`${this.apiUtilsService.getPrefixForOrder(order)}/orders/${order.id}/insurance.json`, {
      insurance: data
    });
  }

  cancelInsurance(order: Order): Observable<HttpResponse<any>> {
    return this._requestService.delete(`${this.apiUtilsService.getPrefixForOrder(order)}/orders/${order.id}/insurance.json`);
  }

  deletePhoto(order: Order, image: Image): Observable<HttpResponse<any>> {
    return this._requestService.delete(`${this.apiUtilsService.getPrefixForOrder(order)}/orders/${order.id}/photos.json`, {
      filename: image.filename
    });
  }

  updateTransportTariff(order: Order, period: OrderPeriod, tariff: TransportTariff): Observable<HttpResponse<any>> {
    return this._requestService.put(`${this.apiUtilsService.getPrefixForOrder(order)}/orders/${order.id}/period/${period.id}/tariff/transport.json`, {
      transport_tariff: {
        minPrice: tariff.min_price,
        minHours: tariff.min_hours,
        additionalHourPrice: tariff.additional_hour_price,
        afterMkadKmPrice: tariff.after_mkad_km_price,
        ttkPrice: tariff.ttk_price,
        hydroelevatorHourPrice: tariff.hydroelevator_hour_price
      }
    });
  }

  updateLoaderTariff(order: Order, period: OrderPeriod, tariff: LoaderTariff): Observable<HttpResponse<any>> {
    return this._requestService.put(`${this.apiUtilsService.getPrefixForOrder(order)}/orders/${order.id}/period/${period.id}/tariff/loader.json`, {
      loader_tariff: {
        minPrice: tariff.min_price,
        minHours: tariff.min_hours,
        additionalHourPrice: tariff.additional_hour_price,
        onTheWayHourPrice: tariff.on_the_way_hour_price
      }
    });
  }

  updateAssemblerTariff(order: Order, period: OrderPeriod, tariff: AssemblerTariff): Observable<HttpResponse<any>> {
    return this._requestService.put(`${this.apiUtilsService.getPrefixForOrder(order)}/orders/${order.id}/period/${period.id}/tariff/assembler.json`, {
      assembler_tariff: {
        minPrice: tariff.min_price,
        minHours: tariff.min_hours,
        additionalHourPrice: tariff.additional_hour_price
      }
    });
  }

  updateLiftingTariff(order: Order, period: OrderPeriod, tariff: LiftingTariff): Observable<HttpResponse<any>> {
    return this._requestService.put(`${this.apiUtilsService.getPrefixForOrder(order)}/orders/${order.id}/period/${period.id}/tariff/lifting.json`, {
      lifting_tariff: {
        minPrice: tariff.min_price,
        minHours: tariff.min_hours,
        additionalHourPrice: tariff.additional_hour_price
      }
    });
  }

  addOrder(cityId: number, clientPhone: string, clientName: string, startDate: Date, endDate: Date): Observable<number> {
    return this._requestService.post(`/freighter/me/orders.json`, {
      cityId,
      clientPhone,
      clientName,
      startDate: Math.round(startDate.getTime() / 1000),
      endDate: Math.round(endDate.getTime() / 1000)
    }).pipe(map(r => r.body.orderId));
  }

  createOrderByDraft(draft: OrderDraft): Observable<number> {
    return this._requestService
      .post(`${this.apiUtilsService.getPrefixForDraft(draft)}/drafts/${draft.id}/orders.json`, {}).pipe(
      map(r => r.body.orderId as number))
    ;
  }

  updateExecutionStatus(order: Order, period: OrderPeriod, status: string, date?: Date): Observable<HttpResponse<any>> {
    return this._requestService.post(`${this.apiUtilsService.getPrefixForOrder(order)}/orders/${order.id}/period/${period.id}/execution/status.json`, {
      status: status,
      date: date ? OrderService.convertDate(date) : null
    });
  }

  revertExecutionStatus(order: Order, period: OrderPeriod): Observable<HttpResponse<any>> {
    return this._requestService.delete(`${this.apiUtilsService.getPrefixForOrder(order)}/orders/${order.id}/period/${period.id}/execution/status.json`);
  }

  bill(order: Order, employer: Employer): Observable<HttpResponse<any>> {
    return this._requestService.post(`${this.apiUtilsService.getPrefixForOrder(order)}/orders/${order.id}/bill.json`, {
      employer: employer.id
    });
  }

  billAccept(order: Order, employer: Employer): Observable<HttpResponse<any>> {
    return this._requestService.post(`${this.apiUtilsService.getPrefixForOrder(order)}/orders/${order.id}/bill/accept.json`, {
      employer: employer.id
    });
  }

  moneyReturned(order: Order):Observable<HttpResponse<any>>{
    return this._requestService.post(`${this.apiUtilsService.getPrefixForOrder(order)}/orders/${order.id}/money/hand.json`,{});
  }

  moneyReturnAccepted(order: Order):Observable<HttpResponse<any>>{
    return this._requestService.post(`${this.apiUtilsService.getPrefixForOrder(order)}/orders/${order.id}/money/hand/accept.json`,{});
  }

  updateTaximeterState(order: Order, period: OrderPeriod, state: boolean): Observable<HttpResponse<any>> {
    return this._requestService.put(`${this.apiUtilsService.getPrefixForOrder(order)}/orders/${order.id}/period/${period.id}/taximeter.json`, {
      state: state ? 'active' : 'pause'
    });
  }

  updateUsingCalculatedPath(order: Order, period: OrderPeriod, state: boolean): Observable<HttpResponse<any>> {
    return this._requestService.put(`${this.apiUtilsService.getPrefixForOrder(order)}/orders/${order.id}/period/${period.id}/use_calculated_path.json`, {
      state: state ? '1' : '0'
    });
  }

  rollbackOrder(order: Order): Observable<HttpResponse<any>> {
    return this._requestService.post(`/support/me/orders/${order.id}/rollback.json`, {});
  }

  sendBill(order: Order): Observable<HttpResponse<any>> {
    return this._requestService.post(`/orders/${order.id}/bill/send.json`, {});
  }

  get requestService(): RequestWithErrorHandlerService {
    return this._requestService;
  }

  private static convertDate(date: Date): string {
    return DateUtils.convertDate(date);
  }
}
