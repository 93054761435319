import {OrderDraft} from "../../_models/order-draft";
import {Order} from "../../_models/order";
import {OptimizationTaskTransport} from "../../_models/optimization-task-transport";
import {SEARCH_STATUSES_GROUPS} from "../../_maps/search-statuses";

/**
 * Состояние поиска транспорта
 */
export class TransportSearchState {
  draft: OrderDraft;
  order: Order;
  transport: OptimizationTaskTransport;
  taxiStatus: string|null;
  taxiStatusDescription: string|null;

  refreshTaxiStatus() {
    this.taxiStatus = null;
    this.taxiStatusDescription = null;

    if(this.order) {
      if(this.order.status === 'accepted') {
        this.taxiStatus = 'execution';
        this.taxiStatusDescription = 'исполняется';
      } else if(this.order.status ==='completed') {
        this.taxiStatus = 'completed';
        this.taxiStatusDescription = 'выполнен';
      }
    } else if(this.draft && this.draft.taxi_search) {
      for(const group in SEARCH_STATUSES_GROUPS) {
        let groupStatuses = SEARCH_STATUSES_GROUPS[group]?.statuses;
        if(groupStatuses && groupStatuses.indexOf(this.draft.taxi_search.status) !== -1) {
          this.taxiStatus = group;
          this.taxiStatusDescription = SEARCH_STATUSES_GROUPS[group].name;
          break;
        }
      }
    }
  }
}
