
import {finalize, switchMap, distinctUntilChanged, debounceTime, map} from 'rxjs/operators';
import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {OrderService} from "../_services/order.service";
import {firstValueFrom, Observable, Subject, Subscription} from "rxjs";
import {Order} from "../_models/order";
import {LoaderService} from "../_services/loader.service";
import {OrderList} from "../_models/order-list";
import {Page} from "../pager/page";
import {OrderListFilter} from "../_models/order-list-filter";
import {State} from "../_storage/state";
import {UserInfoService} from "../_services/user-info.service";
import {NotificationService} from "../_services/notification.service";
import {NotificationEvent} from "../notifications/notification-event";
import {ObjectComparator} from "../_utils/ObjComparator";
import {EVENT_STATUS} from "../calendar/calendar.colors"
import {OrderUtils} from "../_utils/order-utils";
import {DateTime} from "date-time-js";
import {Router} from "@angular/router";
import {OrderPaymentState} from "../_models/order-payment-state";
import {InvoiceService} from "../_services/invoice.service";
import {InvoiceUtils} from "../_utils/invoice-utils";
import {HttpErrorResponse, HttpResponse} from "@angular/common/http";
import {PAYMENT_STATUSES} from "../_maps/payment-statuses";
import {SummaryReport} from "../_models/report/summary-report";
import {CompanyClientService} from "../_services/company-client.service";
import {CompanyClient} from "../_models/company-client";
import {ElementUtils, Offset} from "../_utils/element-utils";
import {AlertService} from "../_services/alert.service";
import {OrderReport} from "../_models/order-report";
import {CityService} from "../_services/city.service";
import {RouteSheetSend} from "../_models/route-sheet-send";
import {Freighter} from "../_models/freighter";
import {Employer} from "../_models/employer";
import {FreightersFastListDialogComponent} from "../freighters-fast-list-dialog/freighters-fast-list-dialog.component";
import {SummaryReportV2} from "../_models/report/summary-report.v2";
import {plural} from "ru-plurals";
import {AdjustmentService} from "../_services/adjustment.service";
import {OrderAdjustmentCostState} from "../_models/order-adjustment-cost-state";

const FILTER_STORAGE = 'order_list_filter';
const PAGE_STORAGE = 'order_list_page';

export class ListCommand {
  private _selectedOrders: number[];

  constructor(private _filter?: OrderListFilter, private _page?: number, private _source?: string, selectedOrders?: number[]) {
    this._selectedOrders = (selectedOrders || []).sort((a, b) => a - b)
  }

  get filter(): OrderListFilter {
    return this._filter;
  }

  get page(): number {
    return this._page;
  }

  get source(): string {
    return this._source;
  }

  get selectedOrders(): number[] {
    return this._selectedOrders;
  }

  equals(cmd: ListCommand): boolean {
    return this._page === cmd.page && this._filter.equals(cmd.filter) && this._selectedOrders === cmd.selectedOrders;
  }

  getClone(filter?: OrderListFilter, page?: number, source?: string, selectedOrders?: number[]): ListCommand {
    return new ListCommand(filter || this._filter.clone(), page || this._page, source || this._source, selectedOrders || this._selectedOrders);
  }

  static comparator(a: ListCommand, b: ListCommand): boolean {
    return a.equals(b);
  }
}

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit, OnDestroy {
  @ViewChild('ordersContainer', { static: true }) ordersContainerEl: ElementRef;
  @ViewChild(FreightersFastListDialogComponent, { static: true }) freighterFiltersDialog: FreightersFastListDialogComponent;

  orders: OrderList = OrderList.empty();
  private listCommands = new Subject<ListCommand>();
  private summaryCommands = new Subject<ListCommand>();
  notificationChangeListener: Subject<NotificationEvent>;
  private oldFilter: OrderListFilter;
  private paginationSource: string;
  statuses = EVENT_STATUS;
  search: string;
  private isSearchFocused = false;
  private status: string;
  orderStatus: string;
  clientStatus: string;
  driverStatus: string;
  after: Date;
  before: Date;
  freightersFilter: Freighter[] = [];
  employersFilter: Employer[] = [];
  companyClients: CompanyClient[] = [];
  companyClientFilter = 0;
  trackNumberFilter: string;
  cargoCodeFilter: string;
  debtFilter = '';
  payMethodFilter: string;

  clientPaymentMenuOffset = new Offset();
  clientPaymentMenuVisible = false;
  clientPaymentMenuOrder: Order;

  driverPayoutMenuOffset = new Offset();
  driverPayoutMenuVisible = false;
  driverPayoutMenuOrder: Order;

  afterDatePickerOptions = {
    autoclose: true,
    todayBtn: 'linked',
    todayHighlight: true,
    assumeNearbyYear: true,
    format: 'dd.mm.yy',
    language: 'ru',
    label: 'от'
  };

  beforeDatePickerOptions = {
    autoclose: true,
    todayBtn: 'linked',
    todayHighlight: true,
    assumeNearbyYear: true,
    format: 'dd.mm.yy',
    language: 'ru',
    label: 'до'
  };

  selectAll = false;
  orderSelects = new Map<number, boolean>();
  selectedOrders = 0;
  hasSelection = false;
  paymentStates = new Map<number, OrderPaymentState>();
  orderPaymentReports = new Map<number, OrderReport>();
  routeSheetSends = new Map<number, RouteSheetSend[]>();
  adjustmentStates = new Map<number, OrderAdjustmentCostState>();
  summaryReport = new SummaryReport();
  summaryReportV2 = new SummaryReportV2();
  refreshStatesCounter = 1;

  private changeCitySubscription: Subscription;

  private ordersPlural = plural('заказ', 'заказа', 'заказов');
  private draftsPlural = plural('заявка', 'заявки', 'заявок');

  constructor(
    private orderService: OrderService,
    private invoiceService: InvoiceService,
    private adjustmentService: AdjustmentService,
    private loaderService: LoaderService,
    private companyClientService: CompanyClientService,
    private router: Router,
    public  userService: UserInfoService,
    public _notificationService: NotificationService,
    private cityService: CityService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.initCityFilter();
    this.initOrders();
    this.initSummaryReport();
    this.initCompanyClients();
    this.restoreFilter();
    this.restorePage();
    if(this.cityService.isActiveCityLoaded) {
      this.loadOrders();
    }
    this.initOrdersCounterListener();

    console.log(this.summaryReport);
  }

  loadOrders() {
    this.listCommands.next(new ListCommand(this.orders.filter, this.orders.page));
  }

  private initCityFilter(): void {
    this.changeCitySubscription = this.cityService.createChangeActiveCityObservable()
      .subscribe(
        () => this.listCommands.next(new ListCommand(this.orders.filter))
      );
  }

  private initOrders() {
    this.listCommands = new Subject<ListCommand>()
    this.listCommands.pipe(
      debounceTime(500),
      distinctUntilChanged(ListCommand.comparator),
      switchMap(command => {
        this.loaderService.show();
        return this.orderService
          .getOrders(command.page, command.filter)
          .pipe(
            map(list => [ list, command ]),
            finalize(() => this.loaderService.hide())
          )
        ;
      })
    ).subscribe({
      next: listAndCommand => {
        let list = listAndCommand[0] as OrderList;
        let command = listAndCommand[1] as ListCommand;

        if(list.orders.length > 0) {
          this.loadPaymentStates(list.orders);
          this.loadOrderPaymentReports(list.orders);
          this.loadRouteSheetSends(list.orders);
          this.loadAdjustments(list.orders);
        }

        if(command.source == 'button' && ObjectComparator.Compare(this.oldFilter, list.filter)) {
          this.orders = list.concat(this.orders.orders);
        } else {
          this.resetSelects();
          this.cleanOrderPaymentReports();
          this.cleanRouteSheetSends();
          this.orders = list;
        }

        this.oldFilter = list.filter.clone();

        this.initSelects();
        this.savePage();
        this.saveFilter();
        // this.refreshFilterFields();

        this.refreshSummary(command);
      },
      error: () => this.initOrders()
    });
  }

  private initCompanyClients() {
    if(!this.userService.isPrivilegedUser())
      return;

    this.loaderService.show();
    this.companyClientService
      .getCompanyClients().pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        companies => this.companyClients = companies,
        () => {}
      )
    ;
  }

  private refreshSummary(command: ListCommand): void {
    if(!this.userService.isPrivilegedUser())
      return;

    let selectedOrdersId = this.getSelectedOrdersId();
    if(selectedOrdersId.length == 0)
      this.summaryReport = new SummaryReport();
    else
      this.summaryCommands.next(command.getClone(null, null, null, selectedOrdersId));
  }

  private forceCalcSummary(): void {
    if(!this.userService.isPrivilegedUser())
      return;

    this.summaryCommands.next(new ListCommand(this.orders.filter));
  }

  private initSummaryReport() {
    this.summaryCommands = new Subject<ListCommand>();
    this.summaryCommands.pipe(
      debounceTime(500),
      distinctUntilChanged(ListCommand.comparator),
      switchMap(command => {
        this.alertService.clear();
        this.loaderService.show();
        return this.orderService
          .getSummaryReportV2(command.filter, command.selectedOrders).pipe(
          finalize(() => this.loaderService.hide()))
        ;
      }),)
      .subscribe(
        report => this.summaryReportV2 = SummaryReportV2.applyAdjustments(report),
        () => this.initSummaryReport()
      )
    ;
  }

  private cleanOrderPaymentReports(): void {
    let newReportsMap = new Map<number, OrderReport>();
    for(let order of this.orders.orders) {
      if(this.orderPaymentReports.has(order.id))
        newReportsMap.set(order.id, this.orderPaymentReports.get(order.id));
    }
    this.orderPaymentReports = newReportsMap;
  }

  private cleanRouteSheetSends(): void {
    this.routeSheetSends.clear();
  }

  private loadOrderPaymentReports(orders: Order[]) {
    if(orders.length == 0)
      return;

    if(!this.userService.isPrivilegedUser())
      return;

    this.loaderService.show();
    this.orderService
      .getOrderReports(orders.map(o => o.id)).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(reports => {
        for(let report of reports)
          this.orderPaymentReports.set(report.order_id, report);
      }, () => {})
  }

  private loadRouteSheetSends(orders: Order[]): void {
    if(!this.userService.isPrivilegedUser() && !this.userService.isFreighter())
      return;

    this.loaderService.show();

    this.orderService
      .getRouteSheetSends(null, orders.map(o => o.id)).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        sends => {
          let routeSheetSends = new Map<number, RouteSheetSend[]>();
          for(let send of sends) {
            routeSheetSends.set(send.order.id, (routeSheetSends.get(send.order.id) || []).concat([ send ]));
          }
          for (let [orderId, sends] of Array.from(routeSheetSends)) {
            this.routeSheetSends.set(orderId, sends);
          }
        },
        () => {}
      );
  }

  private loadAdjustments(orders: Order[]) {
    for(const order of orders) {
      if(!this.adjustmentStates.has(order.id))
        this.adjustmentStates.set(order.id, new OrderAdjustmentCostState(order.id));
    }

    this.loaderService.show();
    this.adjustmentService
      .getAdjustmentsForOrders(orders.map(o => o.id)).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        adjustments => {
          let ordersId = new Set<number>();

          for(const adjustment of adjustments) {
            let state = this.adjustmentStates.get(adjustment.order.id);
            state.add(adjustment);
            ordersId.add(adjustment.order.id);
          }

          for(const orderId of Array.from(ordersId.values()))
            this.adjustmentStates.get(orderId).compile();
        },
        () => {}
      )
    ;
  }

  private async loadPaymentStates(orders: Order[]) {
    for(let order of orders) {
      if(!this.paymentStates.has(order.id)) {
        let state = new OrderPaymentState();
        state.orderId = order.id;

        this.paymentStates.set(state.orderId, state);
      }
    }

    if(!this.userService.isPrivilegedUser())
      return;

    this.loaderService.show();
    let paymentsPromise = await firstValueFrom(
      this.invoiceService
        .getOrdersPayments(orders.map(o => o.id))
        .pipe(
          finalize(() => this.loaderService.hide())
        )
      )
    ;
    let payoutsPromise = await firstValueFrom(
      this.invoiceService
        .getOrdersPayouts(orders.map(o => o.id))
        .pipe(
          finalize(() => this.loaderService.hide())
        )
      )
    ;

    return Promise.all([paymentsPromise, payoutsPromise])
      .then(invoices => {
        InvoiceUtils.invoicesToStates(invoices[0].concat(invoices[1])).forEach(s => this.paymentStates.set(s.orderId, s));
        // изменяем счётчик обновлений, чтобы корректно отрисовались элементы управления заказами
        this.refreshStatesCounter ++;
      })
      .catch(() => {})
  }

  private updateSelectedPaymentStates() {
    this.loadPaymentStates(this.getSelectedOrders());
  }

  private resetSelects() {
    this.orderSelects.clear();
  }

  private initSelects() {
    for(let order of this.orders.orders) {
      if(!this.orderSelects.has(order.id) || this.selectAll)
        this.orderSelects.set(order.id, this.selectAll);
    }
    this.syncSelected();
  }

  private saveFilter() {
    sessionStorage.setItem(FILTER_STORAGE, this.orders.filter.getState().state);
  }

  private restoreFilter() {
    let state = sessionStorage.getItem(FILTER_STORAGE);
    if (state)
      this.orders.filter = OrderListFilter.fromState(new State(state));

    this.refreshFilterFields(true);

    this.oldFilter = this.orders.filter;
  }

  private refreshFilterFields(refresh = false): void {
    this.status = this.orders.filter.status;
    if(this.status == '' && this.userService.isCustomer())
      this.status = 'accepted';

    this.initStatusFilters();

    if(!this.isSearchFocused || refresh)
      this.search = this.orders.filter.search;

    this.after = this.orders.filter.after;
    this.before = this.orders.filter.before;
    this.companyClientFilter = this.orders.filter.companyClient || 0;
    this.debtFilter = (this.orders.filter.debt == null && this.orders.filter.debt !== 0) ? '' : this.orders.filter.debt.toString();
    this.payMethodFilter = this.orders.filter.payMethod || '';
    this.freightersFilter = this.orders.filter.freighters;
    this.employersFilter = this.orders.filter.employers;
    this.trackNumberFilter = this.orders.filter.trackNumber || '';
    this.cargoCodeFilter = this.orders.filter.cargoCode || '';
  }

  private initStatusFilters() {
    let allowedOrderStatuses = [
      'ready',
      'negotiation',
      'new',
      'checking',
      'agree',
      'accepted',
      'edit',
      'completed',
      'not_agree',
      'declined',
      'canceled_by_freighter',
      'canceled_by_employer',
      'canceled_by_client',
      'no_answer',
      'canceled_by_system'
    ];
    let allowedClientStatuses = [ 'has_payment', 'has_not_payment' ];
    let allowedDriverStatuses = [ 'has_payout', 'has_not_payout', 'has_fee', 'has_not_fee' ];

    let orderStatuses = [];
    let clientStatuses = [];
    let driverStatuses = [];

    for(let status of this.status.split(',').map(s => s.trim()).filter(s => s != '')) {
      if(allowedOrderStatuses.indexOf(status) >= 0)
        orderStatuses.push(status);
      else if(allowedClientStatuses.indexOf(status) >= 0)
        clientStatuses.push(status);
      else if(allowedDriverStatuses.indexOf(status) >= 0)
        driverStatuses.push(status);
    }

    this.orderStatus = orderStatuses.join(',');
    this.clientStatus = clientStatuses.join(',');
    this.driverStatus = driverStatuses.join(',');
  }

  private applyStatusFilters() {
    this.status = [ this.orderStatus, this.clientStatus, this.driverStatus ].filter(s => s != '').join(',');
  }

  private savePage() {
    sessionStorage.setItem(PAGE_STORAGE, this.orders.page.toString());
  }

  private cleanFilter(): void {
    this.orders = OrderList.empty();
    this.refreshFilterFields();
    this.listCommands.next(new ListCommand(new OrderListFilter(), 0));
  }

  private restorePage() {
    this.orders.page = parseInt(sessionStorage.getItem(PAGE_STORAGE) || "0");
  }

  onPaginationPage(page: Page) {
    this.listCommands.next(new ListCommand(this.orders.filter.clone(), page.num, 'pager'));
  }

  onShowMorePage(page: Page) {
    this.listCommands.next(new ListCommand(this.orders.filter.clone(), page.num, 'button'));
  }

  onChangeFilter() {
    this.applyStatusFilters();

    let filter = new OrderListFilter(
      this.status, this.search,
      this.companyClientFilter,
      this.debtFilter === '' ? null : parseInt(this.debtFilter),
      this.payMethodFilter,
      this.after,
      this.before,
      this.freightersFilter,
      this.employersFilter,
      this.trackNumberFilter,
      this.cargoCodeFilter,
    );

    if(!filter.equals(this.oldFilter)) {
      console.log('filter changed');
      this.listCommands.next(new ListCommand(filter));
    }
  }

  initOrdersCounterListener() {
    this.notificationChangeListener = this._notificationService.getListener();
    // this.notificationChangeListener.next({command: 'clearNewOrdersCounter'});
    this.notificationChangeListener
      .subscribe(message => {
          if (message.command == 'refreshListView') {
            this.orders.filter = new OrderListFilter();
            this.listCommands.next(new ListCommand(this.orders.filter.clone()));
            this._notificationService.getListener().next({command: 'none'});
          }

          if (message.command == 'updateListDraftGroupStatuses') {
            this.updateStatusInDraftGroup(message.orderId);
          }
        }
      )
  }

  updateStatusInDraftGroup(orderId: number) {
    let order = this.orders.orders.filter(item => item.id == orderId)[0];
    if (typeof order == 'undefined') {
      return false;
    }
    let orderDraftId = order.draft.id;
    this.orders.orders
      .filter(item => item.draft.id == orderDraftId)
      .forEach(item => {
        this.orders.orders[this.orders.orders.indexOf(item)].status = 'canceled_by_system'
      });
    this.orders.orders[this.orders.orders.indexOf(order)].status = 'accepted'
  }

  isPeriodDateEqualsOrderDate(order: Order): boolean {
    let orderDate = new Date(order.created_at);
    let periodStart = new Date(order.periods[0].start);

    return orderDate.getFullYear() == periodStart.getFullYear()
      && orderDate.getMonth() == periodStart.getMonth()
      && orderDate.getDate() == periodStart.getDate();
  }

  private syncSelected() {
    this.selectedOrders = 0;
    this.orderSelects.forEach(selected => this.selectedOrders += selected ? 1 : 0);

    this.hasSelection = this.selectAll || this.selectedOrders > 0;
  }

  isTest(order: Order): boolean {
    return OrderUtils.isTest(order);
  }

  private updateInvoices(loader: (filter: OrderListFilter, ids?: number[]) => Observable<HttpResponse<any>>): void {
    this.updateInvoicesByOrders(this.getSelectedOrders(), loader);
  }

  private updateInvoicesByOrders(orders: Order[], loader: (filter: OrderListFilter, ids?: number[]) => Observable<HttpResponse<any>>): void {
    this.loaderService.show();

    loader(this.orders.filter, orders.map(o => o.id)).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => {
          this.loadPaymentStates(orders);
          this.refreshSummary(new ListCommand(this.orders.filter, this.orders.page));
        },
        () => {}
      );
  }

  private updatePaymentStatus(order: Order, status: string): void {
    this.updateInvoicesByOrders(
      [order],
      (filter, ordersId) => this.orderService.updatePaymentsStatus(status, filter, ordersId)
    );
  }

  private updatePayoutStatus(order: Order, status: string): void {
    this.updateInvoicesByOrders(
      [order],
      (filter, ordersId) => this.orderService.updatePayoutsStatus(status, filter, ordersId)
    );
  }

  private sendBill(order: Order): void {
    this.loaderService.show();

    this.orderService
      .sendBill(order).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => alert('Счёт поставлен в очередь на отправку. Он будет отправлен втечении 10 минут.'),
        e => {
          if (e instanceof HttpErrorResponse) {
            if (e.status == 405) {
              alert("Отправка счёта невозможна (у клиента отсутствует юр. лица?)");
            }
          }
        }
      )
  }

  private showClientPaymentMenuFor(order: Order, offset: Offset): void {
    this.clientPaymentMenuOrder = order;
    this.clientPaymentMenuOffset = offset;
    this.clientPaymentMenuVisible = true;
  }

  private hideClientPaymentMenu(): void {
    this.clientPaymentMenuOrder = null;
    this.clientPaymentMenuVisible = false;
  }

  private showDriverPayoutMenuFor(order: Order, offset: Offset): void {
    this.driverPayoutMenuOrder = order;
    this.driverPayoutMenuOffset = offset;
    this.driverPayoutMenuVisible = true;
  }

  private hideDriverPayoutMenu(): void {
    this.driverPayoutMenuOrder = null;
    this.driverPayoutMenuVisible = false;
  }

  private hidePaymentMenu(): void {
    this.hideClientPaymentMenu();
    this.hideDriverPayoutMenu();
  }

  private getSelectedOrdersId(): number[] {
    let selected: number[] = [];
    this.orderSelects.forEach((sel, id) => {
      if(sel)
        selected.push(id);
    });

    return selected;
  }

  private getSelectedOrders(): Order[] {
    let selectedIds = this.getSelectedOrdersId();
    return this.orders.orders.filter(o => selectedIds.indexOf(o.id) >= 0);
  }

  /**
   * Возвращает процент суммы, полученной указанным методом, от общей суммы
   *
   * @param method
   */
  getPayMethodPercent(method: string): number {
    return this.getPercentFromTotalSum(this.summaryReport[`${method}_sum`] || 0);
  }

  getPercentFromTotalSum(sum: number): number {
    return this.summaryReport.total != 0
      ? (sum / this.summaryReport.total) * 100
      : 0;
  }

  getOrdersCountPlural(count: number): string {
    return this.ordersPlural(count);
  }

  getDraftsCountPlural(count: number): string {
    return this.draftsPlural(count);
  }

  private sendRouteSheets() {
    this.loaderService.show();

    let selectedOrders = this.getSelectedOrders();

    this.orderService
      .sendRouteSheets(this.orders.filter, this.getSelectedOrdersId()).pipe(
      finalize(() => this.loaderService.hide()))
      .subscribe(
        () => {
          this.loadRouteSheetSends(selectedOrders);
          this.alertService.success('Отправка маршрутных листов запланирована');
        },
        () => {}
      );
  }

  formatPercent(value: number): string {
    let str = (Math.round(value * 100) / 100).toString().replace('.', ',');
    return `${str}%`;
  }

  getAdjustmentsState(order: Order): OrderAdjustmentCostState {
    return this.adjustmentStates.get(order.id);
  }

  hasAdjustments(order: Order): boolean {
    return this.adjustmentStates.get(order.id).hasAdjustments;
  }

  formatEmails(emails: string): string {
    return emails.split(',').join(', ');
  }

  getRouteSheetSends(order: Order): RouteSheetSend[] {
    return this.routeSheetSends.get(order.id) || [];
  }

  isRouteSheetSent(send: RouteSheetSend): boolean {
    let scheduled = new DateTime(new Date(send.scheduled_at));
    return scheduled.isLessOrEqual(new DateTime());
  }

  onClickOrder(order: Order): void {
    console.log(`click on order #${order.id}`);

    this.router.navigate(['/orders', order.freighter.id, order.id]);
  }

  onClickSelectAll(): void {
    this.selectAll = !this.selectAll;

    this.orderSelects.forEach((isSelected, id) => this.orderSelects.set(id, this.selectAll));
    this.syncSelected();

    this.refreshSummary(new ListCommand(this.orders.filter, this.orders.page));
  }

  onSelectOrder(order: Order): void {
    this.orderSelects.set(order.id, !this.orderSelects.get(order.id));

    this.selectAll = false;
    this.syncSelected();

    this.refreshSummary(new ListCommand(this.orders.filter, this.orders.page));
  }

  onAcceptOrdersPayment() {
    this.updateInvoices((filter, ordersId) => this.orderService.updatePaymentsStatus(PAYMENT_STATUSES.COMPLETED, filter, ordersId));
  }

  onCancelOrdersPayment() {
    this.updateInvoices((filter, ordersId) => this.orderService.updatePaymentsStatus(PAYMENT_STATUSES.ISSUED, filter, ordersId));
  }

  onAcceptOrdersPayout() {
    this.updateInvoices((filter, ordersId) => this.orderService.updatePayoutsStatus(PAYMENT_STATUSES.COMPLETED, filter, ordersId));
  }

  onCancelOrdersPayout() {
    this.updateInvoices((filter, ordersId) => this.orderService.updatePayoutsStatus(PAYMENT_STATUSES.ISSUED, filter, ordersId));
  }

  onSendRouteSheets() {
    this.sendRouteSheets();
  }

  onForceCalc() {
    this.forceCalcSummary();
  }

  onClickClientPaymentStatus(order: Order, event: Event) {
    if(!this.userService.isPrivilegedUser() || order.pay_method !== 'bank')
      return;

    // console.log(event);
    this.hidePaymentMenu();
    this.showClientPaymentMenuFor(
      order,
      ElementUtils.getRelativeOffset(event.srcElement as HTMLElement, this.ordersContainerEl.nativeElement)
    );
    event.stopPropagation();
  }

  onClickDriverPayoutStatus(order: Order, event: Event) {
    // console.log(this.paymentStates.get(order.id));
    if(!this.userService.isSuperUser() || this.paymentStates.get(order.id).payoutAmount <= 0)
      return;

    this.hidePaymentMenu();
    // console.log(event);
    this.showDriverPayoutMenuFor(
      order,
      ElementUtils.getRelativeOffset(event.srcElement as HTMLElement, this.ordersContainerEl.nativeElement)
    );
    event.stopPropagation();
  }

  onClickComponent() {
    this.hidePaymentMenu();
  }

  onAcceptOrderPayment() {
    this.updatePaymentStatus(this.clientPaymentMenuOrder, PAYMENT_STATUSES.COMPLETED);
  }

  onCancelOrderPayment() {
    this.updatePaymentStatus(this.clientPaymentMenuOrder, PAYMENT_STATUSES.ISSUED);
  }

  onAcceptOrderPayout() {
    this.updatePayoutStatus(this.driverPayoutMenuOrder, PAYMENT_STATUSES.COMPLETED);
  }

  onCancelOrderPayout() {
    this.updatePayoutStatus(this.driverPayoutMenuOrder, PAYMENT_STATUSES.ISSUED);
  }

  onSendBill() {
    this.sendBill(this.clientPaymentMenuOrder);
  }

  onChangedFreightersFilter(freighters: Freighter[]): void {
    this.freightersFilter = freighters;
    this.onChangeFilter();
  }

  onChangesEmployersFilter(employers: Employer[]): void {
    this.employersFilter = employers;
    this.onChangeFilter();
  }

  onShowFreightersDialog(): void {
    this.freighterFiltersDialog.showDialog(this.freightersFilter, this.employersFilter);
  }

  ngOnDestroy(): void {
    this.changeCitySubscription.unsubscribe();
  }

  onCleanFilter() {
    this.cleanFilter();
  }

  onSearchFocused(): void {
    this.isSearchFocused = true;
  }

  onSearchBlurred(): void {
    this.isSearchFocused = false;
  }
}
