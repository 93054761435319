import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {OptimizationTask} from "../_models/optimization-task";
import {OptimizationTaskDestination} from "../_models/optimization-task-destination";
import {NavigationEnd, Router} from "@angular/router";
import {DateTime} from "date-time-js";
import {City} from "../_models/city";
import {CityService} from "../_services/city.service";
import {ComplexDeliveryForm} from "../complex-delivery-form/complex-delivery-form";
import {Storehouse} from "../_models/storehouse";
import {StorehouseService} from "../_services/storehouse.service";
import {StorehouseCreatorComponent} from "../storehouse-creator/storehouse-creator.component";
import {UserInfoService} from "../_services/user-info.service";
import {DeliverySchema} from "../_models/delivery-schema";
import {of, Subject, Subscription, switchMap} from "rxjs";
import {OptimizationTaskCalculationRequest} from "../_models/optimization-task-calculation-request";
import {debounceTime, filter, finalize, map} from "rxjs/operators";
import {OptimizationTaskService} from "../_services/optimization-task.service";
import {OptimizationTaskCalculationResult} from "../_models/optimization-task-calculation-result";
import {HttpErrorResponse} from "@angular/common/http";
import {LoaderService} from "../_services/loader.service";
import {OptimizationTaskAutoBuildTransportsRequest} from "../_models/optimization-task-auto-build-transports-request";
import {AlertService} from "../_services/alert.service";
import {DateTimeslot} from "../date-timeslot/date-timeslot";
import {DateTimeslotComponent} from "../date-timeslot/date-timeslot.component";
import {TimeSlot} from "../_models/time-slot";
import {ExpressDeliveryTimeSlotService} from "../_services/express-delivery-time-slot.service";
import {CityUtils} from "../_utils/city-utils";

const NEW_OPTIMIZATION_TASK_STORAGE = 'new_optimization_task';

@Component({
  selector: 'new-complex-delivery',
  templateUrl: './new-complex-delivery.component.html',
  styleUrls: ['./new-complex-delivery.component.scss']
})
export class NewComplexDeliveryComponent implements OnInit, OnDestroy, AfterViewInit {
  optimizationTask: OptimizationTask = new OptimizationTask();
  @Input() showEvent: EventEmitter<DeliverySchema|null>;
  @Input() modeEvent: EventEmitter<string>;
  @Output() saveEvent = new EventEmitter<void>();
  @Output() registerFormListener = new EventEmitter<ComplexDeliveryForm>();
  @ViewChild(StorehouseCreatorComponent) storehouseCreator: StorehouseCreatorComponent;
  @ViewChild(DateTimeslotComponent) desiredArrivalComponent: DateTimeslotComponent;
  @ViewChild(DateTimeslotComponent) deliveryDateTimeComponent: DateTimeslotComponent;
  @ViewChild('newComplexDelivery', { static: true }) dialogEl: ElementRef;

  datePickerOptions = {
  };


  date: Date;
  cities: City[] = [];
  cityId: number;
  city: City;
  storehouses: Storehouse[] = [];
  storehouseId: number;
  isOptimizationTypeEnabled = false;
  desiredArrival = new DateTimeslot();
  deliveryDateTime = new DateTimeslot();
  arrivalDateTime: Date|null = null;
  requestCalculationResult: OptimizationTaskCalculationResult|null = null;
  isCalculationLoading = false;
  isRouteOptimizationEnabled = true;
  isRouteOptimizationAvailable = false;
  isExpressMode = false;
  enabledDesiredArrival: boolean;
  expressDeliveryTimeSlots: TimeSlot[] = [];
  isDeliveryLate = false;

  mode = 'edit';

  private form: ComplexDeliveryForm;
  private calculationStream = new Subject<void>();
  private deliverySchema: DeliverySchema|null = null;
  private showEventSubscription: Subscription;
  private modeEventSubscription: Subscription;

  private wasShown = false;
  private modalWasInit = false;

  constructor(
    private cityService: CityService,
    private storehouseService: StorehouseService,
    private optimizationTaskService: OptimizationTaskService,
    private expressDeliveryTimeSlotService: ExpressDeliveryTimeSlotService,
    private router: Router,
    private userService: UserInfoService,
    private loaderService: LoaderService,
    private alertService: AlertService
    ) { }

  ngOnInit() {
    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(() => {
        if(this.wasShown)
          this.hideForm();
      });

    this.isRouteOptimizationAvailable = this.userService.isAvailableRouteOptimization();
    this.initDesiredArrival();

    this.initCalculationStream();


    if(this.showEvent != null)
      this.showEventSubscription = this.showEvent.subscribe(deliverySchema => this.onShow(deliverySchema));

    if(this.modeEvent != null)
      this.modeEventSubscription = this.modeEvent.subscribe(mode => {
        this.mode = mode;
        this.requestCalculationResult = this.prepareCalculationRequestResult();
      });

    if(this.registerFormListener != null)
      this.registerFormListener.subscribe(f => this.onRegisterForm(f));
  }

  ngOnDestroy(): void {
    if(this.showEventSubscription) {
      this.showEventSubscription.unsubscribe();
      this.showEventSubscription = null;
    }

    if(this.modeEventSubscription) {
      this.modeEventSubscription.unsubscribe();
      this.modeEventSubscription = null;
    }

    if(this.wasShown)
      this.hideForm();
  }

  ngAfterViewInit(): void {
    if(!this.modalWasInit) {
      this.modalWasInit = true;
      $(this.dialogEl.nativeElement).on('hidden.bs.modal', () => {
        this.wasShown = false;
      }).on('show.bs.modal', () => {
        this.wasShown = true;
      });
    }
  }

  private initDesiredArrival(): void {
    this.enabledDesiredArrival = !this.isRouteOptimizationAvailable;
  }

  private initControls(): void {
    this.isOptimizationTypeEnabled = this.isRouteOptimizationAvailable;
  }

  initExpressDeliveryTimeSlots(): void {
    if(this.deliverySchema?.is_express) {
      this.expressDeliveryTimeSlotService
        .getTimeSlots()
        .subscribe(s => this.expressDeliveryTimeSlots = s)
        ;
    }
  }

  private initCalculationStream(): void {
    this.calculationStream = new Subject<void>();
    this.calculationStream.pipe(
      debounceTime(1000),
      map(() => {
        this.saveTask();
        return this.prepareCalculationRequestResult();
      }),
      switchMap(result => {
        if(result.hasErrors || result.unavailableCalculation)
          return of(result);

        this.isCalculationLoading = true;

        return this.optimizationTaskService.calculate(this.buildCalculationRequest()).pipe(
          finalize(() => this.isCalculationLoading = false),
          map(calculation => {
            result.calculation = calculation.body;
            return result;
          })
        )
      })
    ).subscribe({
      next: result => {
        this.requestCalculationResult = result;
        this.setupArrivalDateTime();
        this.setupDeliveryDate();
        this.checkForDeliveryPossibility();
      },
      error: e => {
        console.log(e);
        if(e instanceof HttpErrorResponse) {
          let message = e.error['message'] || `Ошибка '${e.statusText}' (${e.status})`;
          console.log(message);

          this.requestCalculationResult = new OptimizationTaskCalculationResult();
          this.requestCalculationResult.hasErrors = true;
          this.requestCalculationResult.errorMessage = e.status == 400
            ? 'Не можем подобрать тариф под данный заказ. Возможно, груз слишком велик.'
            : 'При расчёте произошла ошибка. Попробуйте повторить позже.';

          if(e.status == 400)
            this.alertService.clear();

        }
        this.initCalculationStream();
      }
    });
  }

  private setupArrivalDateTime(): void {
    if(!this.isExpressMode || !this.deliveryDateTime.isValid())
      return;

    let calculations = this.requestCalculationResult?.calculation?.calculations || [];

    let arrivalTimes = calculations
      .map(c => (c.destinations || [])[0]?.arrival_time)
      .filter(t => !!t)
      .map(t => new Date(t).getTime())
    ;

    let arrivalTime = arrivalTimes.length > 0 ? Math.min(...arrivalTimes) : 0;
    this.arrivalDateTime = arrivalTime ? new Date(arrivalTime) : null;
  }

  private setupDeliveryDate(): void {
    if(this.isExpressMode || this.userService.isAvailableRouteOptimization())
      return;

    let calculation = this.requestCalculationResult?.calculation;
    if(!calculation)
      return;

    let deliveryInterval = calculation.delivery_interval || [];
    let deliveryDate = deliveryInterval[1];
    if(deliveryDate)
      this.date = new Date(deliveryDate);
  }

  private checkForDeliveryPossibility(): void {
    this.isDeliveryLate = false;

    if(!this.isExpressMode || !this.deliveryDateTime.isValid())
      return;

    let calculations = this.requestCalculationResult?.calculation?.calculations || [];
    for(let calculation of calculations) {
      let destinations = calculation.destinations || [];
      if(destinations.length < 2)
        continue;

      let storehouseArrivalTime = destinations[0].arrival_time;
      let firstDestinationArrivalTime = destinations[1].arrival_time;
      if(!storehouseArrivalTime || !firstDestinationArrivalTime)
        continue;

      let toFirstPointDuration = (new Date(firstDestinationArrivalTime).getTime() - new Date(storehouseArrivalTime).getTime()) / 1000 / 60;
      let fullDuration = calculation.calculation['minutes'] || 0;
      let restDuration = Math.round(Math.max(0, fullDuration - toFirstPointDuration));
      let completeOfDelivery = new DateTime(new Date(firstDestinationArrivalTime));
      if(restDuration > 0)
        completeOfDelivery.add(restDuration, 'minute');

      this.isDeliveryLate = completeOfDelivery.isGreater(this.deliveryDateTime.getCombinedTimeEnd());
      console.log(firstDestinationArrivalTime, completeOfDelivery.toDate(), this.deliveryDateTime.getCombinedTimeEnd(), this.isDeliveryLate, restDuration);
      if(this.isDeliveryLate)
        break;
    }

  }

  private save() {
    if(this.storehouseId > 0) {
      this.rawSave();
    } else {
      this.storehouseCreator.save(storehouse => {
        this.storehouses.push(storehouse);
        this.storehouseId = storehouse.id;
        this.rawSave();
      });
    }
  }

  private applyDataToTask(task: OptimizationTask): void {
    task.storehouse_id = this.storehouseId;

    if(this.date)
      task.date = this.date.toString();
    if(this.city)
      task.city = this.city;

    // Здесь важен порядок вызова saveEvent и applyDeliveryTimeSlot.
    // При вызове saveEvent внутренние значения редакторов записываются в destination-ы задачи.
    // Затем вызывается applyDeliveryTimeSlot, который при необходимости переписывает значения тайм-слотов на глобальное
    // значение, указанное в дате и времени доставки клиенту.

    this.saveEvent.emit();

    if(this.isExpressMode)
      this.applyDeliveryTimeSlot();
  }

  private applyDeliveryTimeSlot(): void  {
    let startTime = this.deliveryDateTime.timeBegin;
    let endTime = this.deliveryDateTime.timeEnd;

    if(!startTime || !endTime)
      return;

    let timeSlotBeginStr = startTime.toString();
    let timeSlotEndStr = endTime.toString();

    for(let destination of this.optimizationTask.destinations) {
      if(!destination.stock) {
        destination.time_slot_begin = timeSlotBeginStr;
        destination.time_slot_end = timeSlotEndStr;
      }
    }
  }

  private rawSave() {
    this.applyDataToTask(this.optimizationTask);

    this.saveEvent.emit();
    this.form.submit();
  }

  private validate(): boolean {
    return this.form && this.form.validate();
  }

  private validateForCalculation(): boolean {
    return this.form && this.form.validateForCalculation();
  }

  private validateStorehouseForCalculationResult(result: OptimizationTaskCalculationResult): boolean {
    if(this.storehouseId == -1) {
      if(!this.storehouseCreator?.isCoordinatesValid) {
        result.hasErrors = true;
        result.errorMessage = 'Укажите корректные координаты склада';
        return false;
      }
    } else if(!this.storehouseId) {
      result.hasErrors = true;
      result.errorMessage = 'Выберите склад';
      return false;
    }
    return true;
  }

  private prepareCalculationRequestResult(): OptimizationTaskCalculationResult {
    let result = new OptimizationTaskCalculationResult();

    result.hasErrors = false;

    if(this.mode == 'import') {
      result.unavailableCalculation = true;
      return result;
    }

    result.unavailableCalculation = false;

    if(!this.userService.isAvailableRouteOptimization() && this.optimizationTask.destinations.length > this.optimizationTaskService.getMaxDestinationsCountWithoutOptimization()) {
      result.hasErrors = true;
      result.errorMessage = 'Превышено максимальное количество адресов в заказе';
    } else if(!this.validateForCalculation()) {
      result.hasErrors = true;
      result.errorMessage = 'Не все данные заполнены корректно';
    }

    this.validateStorehouseForCalculationResult(result);

    return result;
  }

  private buildCalculationRequest(): OptimizationTaskCalculationRequest {
    let request = new OptimizationTaskCalculationRequest();

    if(this.storehouseId == -1) {
      let storehouse = new Storehouse();
      storehouse.lat = this.storehouseCreator.lat;
      storehouse.lon = this.storehouseCreator.lng;

      if(this.storehouseCreator.isOpenAfterValid && this.storehouseCreator.isOpenBeforeValid) {
        storehouse.open_after = this.storehouseCreator.openAfter;
        storehouse.open_before = this.storehouseCreator.openBefore;
      }

      request.storehouse = storehouse;
    } else {
      request.storehouse = this.storehouses.find(s => s.id == this.storehouseId);
    }

    this.setupArrivalDataToRequest(request);

    request.task = this.optimizationTask;
    request.isRouteOptimizationEnabled = this.isRouteOptimizationEnabled;

    return request;
  }

  private buildAutoBuildTransportsRequest(): OptimizationTaskAutoBuildTransportsRequest {
    let request = new OptimizationTaskAutoBuildTransportsRequest();

    request.isRouteOptimizationEnabled = this.isRouteOptimizationEnabled;
    this.setupArrivalDataToRequest(request);

    return request;
  }

  private setupArrivalDataToRequest(request: OptimizationTaskAutoBuildTransportsRequest|OptimizationTaskCalculationRequest): void {
    if(this.deliverySchema.is_express)
      return;

    request.desiredArrivalDate = this.desiredArrival.getFormattedDate();
    request.desiredArrivalTimeBegin = this.desiredArrival.getFormattedTimeBegin();
    request.desiredArrivalTimeEnd = this.desiredArrival.getFormattedTimeEnd();
  }

  private initTask() {
    this.optimizationTask.optimization_type = 'from_storehouse';
    this.optimizationTask.delivery_schema = this.deliverySchema;

    let date = new DateTime();
    date.add(1, 'day');

    this.date = date.toDate();
    this.deliveryDateTime.date = date.toDate();

    if(this.optimizationTask.destinations.length > 0)
      return;

    let destination = new OptimizationTaskDestination();
    destination.stock = false;
    destination.priority = 0;

    this.optimizationTask.destinations.push(destination);
  }

  private initCities(refresh = false): void {
    this.cityService
      .getCities()
      .subscribe(cities => {
        this.cities = cities;
        if(refresh || !this.cityId && cities.length > 0)
          this.city = this.cityService.activeCity || CityUtils.getDefaultCity(cities);
        this.cityId = this.city && this.city.id;
        this.initCityByAvailableStoreHouses();
      })
    ;
  }

  private initStorehouses(): void {
    // сохраняем текущее значение выбранного склада и обнуляем выбранный склад
    let storehouseId = this.storehouseId;
    this.storehouseId = null;
    this.storehouseService
      .getAvailableStorehouses()
      .subscribe(
        storehouses => {
          this.storehouses = storehouses;
          // восстанавливаем выбранный склад, чтобы обновился select.
          this.storehouseId = storehouseId;
          this.initCityByAvailableStoreHouses();
          if(storehouses.length > 0 && !this.storehouseId)
            this.storehouseId = storehouses[0].id;
        },
        () => {}
        )
    ;
  }

  private initCityByAvailableStoreHouses(): void {
    if(this.storehouses.length == 0)
      return;

    let storehouse = this.storehouses[0];
    this.city = storehouse.city;
    this.cityId = this.city.id;
  }

  private initExpressMode(): void {
    this.isExpressMode = this.deliverySchema?.is_express || false;
    this.initExpressDeliveryTimeSlots();
  }

  private showForm() {
    let taskRestored = this.restoreTask();
    if(!taskRestored) {
      this.optimizationTask = new OptimizationTask();
      this.initTask();
      this.deliveryDateTime.date = this.date;
    }
    this.initStorehouses();
    this.initControls();
    this.initCities(!taskRestored);
    this.initExpressMode();
    this.requestCalculation();
    $(this.dialogEl.nativeElement).modal('show');
  }

  private hideForm() {
    $(this.dialogEl.nativeElement).modal('hide');
  }

  private requestCalculation() {
    if(!this.isRouteOptimizationAvailable) {
      this.calculationStream.next();
    }
  }

  private autoBuildTransports(): void {
    this.loaderService.show();
    this.loaderService.showText('Подготовка рейсов...');
    this.optimizationTaskService
      .autoBuildTaskTransports(this.optimizationTask, this.buildAutoBuildTransportsRequest())
      .pipe(finalize(() => this.loaderService.hide()))
      .subscribe({
        next: () => {
          this.desiredArrivalComponent?.removeSavedForTask(this.optimizationTask);
          if(this.userService.isAvailableRouteOptimization())
            this.navigateToTask();
          else
            this.executeTask();
        },
        error: () => {
          this.desiredArrivalComponent?.saveForTask(this.optimizationTask);
          this.navigateToTask();
        }
      })
    ;
  }

  private executeTask(): void {
    this.loaderService.show();
    this.loaderService.showText('Запуск подбора...');
    this.optimizationTaskService
      .executeTask(this.optimizationTask)
      .pipe(finalize(() => this.loaderService.hide()))
      .subscribe({
        next: () => {
          this.navigateToTask();
        },
        error: () => {
          this.navigateToTask();
        }
      })
    ;
  }

  private navigateToTask(): void {
    this.router.navigate([ `/complex-deliveries/${this.optimizationTask.id}` ]);
  }

  private saveTask(): void {
    if(!this.wasShown)
      return;

    this.applyDataToTask(this.optimizationTask);
    localStorage.setItem(NEW_OPTIMIZATION_TASK_STORAGE, JSON.stringify(this.optimizationTask));
  }

  private restoreTask(): boolean {
    let task = localStorage.getItem(NEW_OPTIMIZATION_TASK_STORAGE);
    if(task) {
      let restoredTask = JSON.parse(task) as OptimizationTask;
      restoredTask.delivery_schema = this.deliverySchema || restoredTask.delivery_schema;
      if(restoredTask.city) {
        this.city = restoredTask.city;
        this.cityId = restoredTask.city.id;
      }
      if(restoredTask.date) {
        this.date = new Date(restoredTask.date);
      }

      if(restoredTask.storehouse_id)
        this.storehouseId = restoredTask.storehouse_id;

      this.optimizationTask = restoredTask;

      return true;
    }
    return false;
  }

  private removeSavedTask(): void {
    localStorage.removeItem(NEW_OPTIMIZATION_TASK_STORAGE);
  }

  private applyDeliveryDateTime(): void {
    if(!this.deliveryDateTime)
      return;

    if(this.deliveryDateTime.date) {
      this.optimizationTask.date = this.deliveryDateTime.date.toString();
      this.date = new Date(this.deliveryDateTime.date);
    }
  }

  onAccept() {
    if(this.validate())
      this.save();
    else
      alert("Не все поля заполнены правильно. Исправьте их и повторите попытку.");
  }

  onShow(deliverySchema?: DeliverySchema) {
    this.deliverySchema = deliverySchema;
    this.showForm();
  }

  onRegisterForm(form: ComplexDeliveryForm) {
    this.form = form;

    console.log('Form was registered');
  }

  onSelectCity(): void {
    for(let city of this.cities) {
      if(city.id == this.cityId) {
        this.city = city;
        console.log(`City "${city.name}" was selected`);
      }
    }
  }

  onSaved(id: number) {
    this.removeSavedTask();
    this.desiredArrivalComponent?.removeSavedForTask(this.optimizationTask);
    this.optimizationTask.id = id;
    this.desiredArrivalComponent?.saveForTask(this.optimizationTask);
    this.hideForm();

    if(this.userService.isAvailableRouteOptimization()) {
      this.navigateToTask();
      return;
    }

    this.loaderService.show();
    this.loaderService.showText('Проверка загруженных адресов...');
    this.optimizationTaskService
      .checkTaskForAutoBuildTransports(this.optimizationTask)
      .pipe(finalize(() => this.loaderService.hide()))
      .subscribe(allowed => {
        if(allowed)
          this.autoBuildTransports();
        else
          this.navigateToTask();
      });
  }

  onSaveFailed(id: number): void {
    this.removeSavedTask();
    this.optimizationTask.id = id;
    this.desiredArrivalComponent?.saveForTask(this.optimizationTask);
    this.hideForm();
    this.alertService.warning('Не все данные удалось импортировать', true);
    this.navigateToTask();
  }

  onChangeMode() {
    this.mode = this.mode == 'edit' ? 'import' : 'edit';
    this.requestCalculationResult = this.prepareCalculationRequestResult();
  }

  onTaskUpdated(): void {
    if(!this.wasShown)
      return;

    this.saveTask();
    this.requestCalculation();
  }

  onChangeStorehouse(): void {
    if(!this.wasShown)
      return;

    this.saveTask();
    this.requestCalculation();
  }

  onChangeDesiredArrival(): void {
    if(!this.wasShown)
      return;

    this.saveTask();
    this.desiredArrivalComponent?.saveForTask(this.optimizationTask);
    this.requestCalculation();
  }

  onChangeDeliveryDateTime(): void {
    if(!this.wasShown)
      return;

    this.saveTask();
    this.deliveryDateTimeComponent?.saveForTask(this.optimizationTask);
    this.applyDeliveryDateTime();
    this.requestCalculation();
  }
}
